import { Buffer } from "buffer"

export const throttle = <A = unknown>(func: (args: A) => void, delay: number): ((args: A) => void) => {
	let inThrottle: boolean
	let timeout: NodeJS.Timeout | undefined
	return (...args): void => {
		if (!inThrottle) {
			func.apply(this, args)
			inThrottle = true
			clearTimeout(timeout)
			timeout = setTimeout(function () {
				inThrottle = false
			}, delay)
		}
	}
}

export const chunk = (arr: any[], chunkSize = 1, cache: any[] = [], middleware?: (arr: any[]) => any) => {
	const tmp = [...arr]
	if (chunkSize <= 0) return cache
	let tmpChunk = tmp.splice(0, chunkSize)
	if (middleware) tmpChunk = middleware(tmpChunk)
	while (tmp.length) cache.push(tmpChunk)
	return cache
}

export const debounce = (func: Function, delay: number) => {
	let timeout: NodeJS.Timeout | undefined
	return (...args: any[]) => {
		return new Promise((resolve, reject) => {
			clearTimeout(timeout)
			timeout = setTimeout(() => {
				try {
					const output = func(...args)
					resolve(output)
				} catch (err) {
					console.error(err)
					reject(err)
				}
			}, delay)
		})
	}
}

export const utf8_to_b64 = (str: string) => window.btoa(decodeURIComponent(encodeURIComponent(str)))

export const removeHash = () => window.history.replaceState(null, "", " ")

export const base64Decode = (data: string) => {
	let text = ""
	if (typeof data !== "undefined") {
		try {
			const buff = Buffer.from(data, "base64")
			text = buff.toString("ascii")
		} catch (err) {
			console.log(err)
		}
		return text
	}
	return ""
}

export const convertHexToRGBA = (hexCode: string, opacity = 1) => {
	let hex = hexCode.replace("#", "")

	if (hex.length === 3) {
		hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
	}

	const r = parseInt(hex.substring(0, 2), 16)
	const g = parseInt(hex.substring(2, 4), 16)
	const b = parseInt(hex.substring(4, 6), 16)

	/* Backward compatibility for whole number based opacity values. */
	if (opacity > 1 && opacity <= 100) {
		opacity = opacity / 100
	}

	return `rgba(${r},${g},${b},${opacity})`
}

export const replaceRBGAWtihNewOpacity = (rgba: string, opacity = 1) => {
	/* Backward compatibility for whole number based opacity values. */
	if (opacity > 1 && opacity <= 100) {
		opacity = opacity / 100
	}
	return rgba.replace(/[\d.]+\)$/g, `${opacity})`)
}

export const replaceColorWithOpacity = (color: string, opacity = 1) => {
	if (color.includes("rgba")) {
		return replaceRBGAWtihNewOpacity(color, opacity)
	} else if (color.includes("#")) {
		return convertHexToRGBA(color, opacity)
	}
}

export const getServerUrlFromDomain = (host: string) => {
	return {
		"staging.remotehq.co.nz": "https://api-dev.remotehq.co.nz",
		"dev.remotehq.co.nz": "https://api-dev.remotehq.co.nz",
		"iwi-dev.remotehq.co.nz": "https://73umkui63s.ap-southeast-2.awsapprunner.com",
		"beta.remotehq.co.nz": "https://api.remotehq.co.nz",
		"remotehq.co.nz": "https://api.remotehq.co.nz",
		"ngatiapakiterato.remotehq.co.nz": "https://x2zpgfxvpt.ap-southeast-2.awsapprunner.com",
	}[host]
}
export const get = (obj: any, path: string | string[], defValue?: any) => {
	if (!path) return undefined

	const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)

	const result = pathArray?.reduce((prevObj, key) => prevObj && prevObj[key], obj)
	return result === undefined ? defValue : result
}
